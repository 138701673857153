<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex';
// import axios from 'axios';

export default {
  name: 'App',
  methods: {
    ...mapActions('auth', ['logoutUser']),
    removeUser(e) {
      this.logoutUser();
      // e.preventDefault();

      // localStorage.removeItem('token');
      // localStorage.removeItem('user');
      // axios.defaults.headers.common['Authorization'] = '';

      // await axios.get('/auth/logout');

      e.returnValue = "";
    },
    forceLogout() {
      this.logoutUser();
    },
  },
  beforeMount() {
    // document.addEventListener('beforeunload', this.removeUser);
    window.addEventListener('beforeunload', this.removeUser);
    // window.addEventListener('beforeunload', function (ev) {
    //   this.logoutUser();
    //   return ev.returnValue = 'My reason';
    // });
  },
  created() {
    this.forceLogout();
  }
};
</script>

<style lang="scss">
  @import 'styles/base';

  @import '~vuetify/src/styles/main.sass';
</style>