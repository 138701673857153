export default {
  namespaced: true,
  state: {
    timeRange_start: '',
    timeRange_end: ''
  },
  mutations: {
    SET_TIMERANGE_START(state, date) {
      state.timeRange_start = date
    },
    SET_TIMERANGE_END(state, date) {
      state.timeRange_end = date
    }
  },
  actions: {
    setStartTime({ commit }, date) {
      commit('SET_TIMERANGE_START', date);
    },
    clearStartTime({ commit }, date) {
      commit('SET_TIMERANGE_START', date);
    },
    setEndTime({ commit }, date) {
      commit('SET_TIMERANGE_END', date);
    },
    clearTimeRange({ commit }) {
      commit('SET_TIMERANGE_START', '');
      commit('SET_TIMERANGE_END', '');
    },
    // clearStartTime({ commit }) {
    //   commit('CLEAR_TIMERANGE_START', '');
    // },
    clearEndTime({ commit }) {
      commit('CLEAR_TIMERANGE_END', '');
    }
  },
};
