<template>
  <v-row justify="center">
    <v-dialog
      v-model="enddatetime_dialog"
      persistent :retain-focus="false"
      width="auto"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Select Date</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-date-picker v-model="selectDate" :allowed-dates="disableDates"></v-date-picker>
              </v-col>
              
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="cancelModal()"
          >
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="saveModal()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      selectDate: ""
    }
  },
  props: ['value'],

  computed: {
    ...mapState('service', {
      startDate: (state) => state.timeRange_start,
    }),
    enddatetime_dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions('service', ['setStartTime','setEndTime', 'clearEndTime']),
    disableDates(val) {
      if (val < this.startDate || new Date().toISOString().substr(0, 10) <= val) {
        return false;
      } else {
        return true;
      }
    },
    cancelModal() {
      this.clearEndTime();
      this.enddatetime_dialog = false;
    },
    saveModal() {
      this.setEndTime(this.selectDate);
      this.enddatetime_dialog = false;
    }
  }
};
</script>