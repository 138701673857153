import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import config from './config';
import * as VueGoogleMaps from 'vue2-google-maps'
import { AuthMixin } from './mixins/auth';
import VueMoment from 'vue-moment';

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = 'application/json';
localStorage.removeItem('token');
const token = localStorage.getItem('token');
// console.log("token", token)
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB7OXmzfQYua_1LEhRdqsoYzyJOPh9hGLg',
  },
});
Vue.use(VueMoment);

Vue.mixin(AuthMixin);
Vue.config.productionTip = false;

localStorage.removeItem('token');
localStorage.removeItem('user');
axios.defaults.headers.common['Authorization'] = '';
      
// store.dispatch('auth/doInit');

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
  store,
}).$mount('#app');
