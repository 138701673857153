<template>
  <v-container fluid class="user-list mt-3">
    <v-row class="ma-0">
      <v-card width="100%" class="ma-1">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-end mt-3">
              <div :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 250px'">
                <v-text-field
                  v-model="search"
                  dense
                  :full-width=$vuetify.breakpoint.smAndDown
                  outlined
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ma-1">
          <v-card-text>
            <v-data-table
              :loading="isReceiving || isDeleting"
              loading-text="Loading... Please wait"
              class="users-table"
              show-select
              :headers="headers"
              :items="items"
              sort-by="createdAt"
              :search="search"
              :items-per-page="itemPerPage">
              <!-- <template v-slot:top>
                <v-dialog v-model="dialog" max-width="500px">
                  <v-card class="edit-dialog" >
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field outlined v-model="editedItem.firstName" label="Name"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field outlined v-model="editedItem.email" label="Email"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-select hide-details outlined :items="role" v-model="editedItem.role" :value="editedItem.role" label="Role"></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field hide-details outlined value="Flatlogic" label="Company"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select hide-details outlined :items="images" v-model="editedItem.img" label="Image">
                              <template v-slot:item="{ item }">
                                <v-img :src="item" width="50" style="margin: 2px"></v-img>
                              </template>
                            </v-select>
                          </v-col>
                          <v-col cols="12" class="d-flex align-center">
                            <v-switch
                              class="disabledUser"
                              v-model="editedItem.disabled"
                              :label="`${editedItem.firstName}: ${editedItem.disabled ? 'Inactive' : 'Active'}`"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" outlined @click="close">Cancel</v-btn>
                      <v-btn color="success" class="button-shadow" @click="save">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template> -->
              <!-- <template v-slot:item.image="{ item }">
                <v-img v-if="item.role === 'admin'" class="my-3" width="34" src="../../../assets/img/user/avatars/1.png"></v-img>
                <v-avatar
                  v-else
                  color="warning"
                  size="34">
                  <span class="white--text headline">{{ item.firstName[0].toUpperCase() }}</span>
                </v-avatar>
              </template> -->
              <!-- <template v-slot:item.firstName="{ item }">
                <a class="primaryConst--text">{{ item.firstName }}</a>
              </template>
              <template v-slot:item.lastName="{ item }">
                <a class="primaryConst--text">{{ item.lastName }}</a>
              </template>
              <template v-slot:item.role="{ item }">
                {{ item.role }}
              </template>
              <template v-slot:item.company>
                Flatlogic
              </template>
              <template v-slot:item.disabled="{ item }">
                <v-chip
                  :color="!item.disabled ? 'primaryConst white--text' : 'secondaryConst white--text'"
                  small>
                  {{ item.disabled ? 'Inactive' : 'Active' }}
                </v-chip>
              </template>
              <template v-slot:item.createdAt="{ item }">
                {{ new Date(item.createdAt).toLocaleString("en-US", { year: 'numeric', month: 'numeric', day: 'numeric' }) }}
              </template> -->
              <template v-slot:item.actions="{ item }">
                <!-- <v-btn icon color="primary" @click="editItem(item)">
                  <v-icon>mdi-pencil-outline</v-icon>
                </v-btn> -->
                <v-btn icon color="primary" @click="viewUser(item)">
                  <v-icon>mdi-information-outline</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="userLocation(item)">
                  <v-icon>mdi-map-marker</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="userAirplane(item)">
                  <v-icon>mdi-airplane</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="userAlarm(item)">
                  <v-icon>mdi-bell-ring</v-icon>
                </v-btn>
                <v-btn icon color="primary" @click="userJaesilState(item)">
                  <v-icon>mdi-home-account</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserList',
  data() {
    return {
      search: '',
      items: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'EMail', value: 'email' },
        { text: 'Phone', value: 'mobilephone' },
        { text: 'createdAt', value: 'createdAt' },
        { text: 'updatedAt', value: 'updatedAt' },
        { text: 'deletedAt', value: 'deletedAt' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      selected: [],
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit User'
    },
  },
  created () {
    this.getItems();
  },
  methods: {
    getItems() {
        this.items = [];
        axios
          .post(`/users/admin_allusers`)
          .then((res) => {
            if (res.status === 200) {
              this.items = res.data;
              // console.log(this.items)
              if (this.items.length > 0) {
                //this.selectRow(0);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
    viewUser(item) {
      this.$router.push({ name: 'UserDetail', params: { user: item }})
    },
    userLocation(item) {
      this.$router.push({ name: 'UserLocation', params: { user: item }})
    },
    userAirplaneDay(item) {
      this.$router.push({ name: 'UserAirplain', params: { user: item }})
    },
    userAlarm(item) {
      console.log(item.id)
    },
    userJaesilState(item) {
      console.log(item.id)
    },
  }


}
</script>

<!-- <style src="./List.scss" lang="scss"></style> -->
