import Vue from 'vue';
import Router from 'vue-router';

import Layout from '@/components/Layout/Layout';
import Login from '@/pages/Login/Login';
import Profile from '@/pages/Profile/Profile';
import Error from '@/pages/Error/Error';

import UserList from '@/pages/User/UserList'
import UserDetail from '@/pages/User/UserDetail'
import UserLocation from '@/pages/User/UserLocation'
import UserSensors from '@/pages/User/UserSensors'
import UserSensorEvents from '@/pages/User/UserSensorEvents'

import Dashboard from '@/pages/Dashboard/Dashboard';

import { isAuthenticated } from './mixins/auth';


import Devices from '@/pages/Devices/Devices'

Vue.use(Router);

export default new Router({
  routes: [
    { path: '/', redirect: { name: 'Profile' } },
    //{ path: '/', redirect: { name: 'Login' } },
    {
      path: '/login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/app',
      name: 'Layout',
      component: Layout,
      redirect: { name: 'Profile' },
      beforeEnter: (to, from, next) => {
        isAuthenticated() ? next() : next({ path: '/login' });
      },
      children: [
        // main pagDeviceses
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
        },
        {
          path: 'devices',
          name: 'Devices',
          component: Devices,
        },
        {
          path: 'profile',
          name: 'Profile',
          component: Profile,
        },
        {
          path: 'userlist',
          name: 'Userlist',
          component: UserList,
        },
        {
          path: 'userdetail',
          name: 'UserDetail',
          component: UserDetail,
        },
        {
          path: 'userlocation',
          name: 'UserLocation',
          component: UserLocation,
        },
        {
          path: 'usersensors',
          name: 'UserSensors',
          component: UserSensors,
        },
        {
          path: 'usersensorevents',
          name: 'UserSensorEvents',
          component: UserSensorEvents,
        },
      ],
    },
    {
      path: '*',
      name: 'Error',
      component: Error,
    },
  ],
});
