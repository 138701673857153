<template>
  <div>
    <div class="dashboard-page">
      <v-row clas="mb-n3" justify="end">
        <v-col class="d-flex" cols="9">
          <v-col class="d-flex" cols="12" sm="6" md="4" lg="4">
            <v-select
              v-model="device_value"
              :items="device_items"
              label="Device"
              @change="onDeviceChange(`${device_value}`)"
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="6" md="4" lg="4">
            <v-select
              v-model="field_value"
              :items="field_items"
              label="Field"
              multiple
            ></v-select>
          </v-col>

          <v-col class="d-flex" cols="12" sm="6" md="4" lg="3">
            <v-text-field label="Range" readonly v-model=timeRange></v-text-field>
          </v-col>
          <v-col class="d-flex pa-5 pt-5" cols="12" sm="6" md="4" lg="1">
            <v-btn color="primary" @click.stop="dialog = true">>></v-btn>
            <TimeRange
              v-model="dialog"
            ></TimeRange>
          </v-col>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-col>
        <v-col class="d-flex pa-5 pt-5" cols="3">
          <v-spacer></v-spacer>
          <v-col class="d-flex" cols="12" sm="6" md="4" lg="4">
            <v-btn color="primary" @click="sendQuery" :loading="isLoading"> Run </v-btn>
          </v-col>
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" class="mx-1">
          <v-tabs
          centered
          grow
        >
        <v-tabs-slider></v-tabs-slider>
          <v-tab
            :href="`#tab-table`"
          >
            Table
          </v-tab>
          <v-tab
            :href="`#tab-chart`"
          >
            Graph
          </v-tab>
          <v-tab-item
            :value="'tab-table'"
          >
            <v-col cols="12">
              <v-card class="employee-list mb-1">
                <v-card-title class="pa-5 pb-3">
                  <v-text-field
                    v-model="tableProperty.search"
                    append-icon="mdi-magnify"
                    label="Search"
                    clearable
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-menu v-if="countTableData">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                      >
                        <v-icon color="greyTint">mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, i) in tableMenu"
                        :key="i"
                        @click="changeTableData(i)"
                      >
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  
                </v-card-title>
                <v-data-table
                  v-model="tableProperty.selected"
                  :headers="tableProperty.headers"
                  :items="tableData[tableDataIndex]"
                  :search="tableProperty.search"
                  item-key="time"
                >
                </v-data-table>
              </v-card>
            </v-col>
          </v-tab-item>
          <v-tab-item
            :value="'tab-chart'"
          >
            <v-col cols="12">
              <v-card class="mx-1 mb-1">
                <v-card-title class="pa-5 pb-3">
                  <p>Data Chart</p>
                  <v-spacer></v-spacer>
                  
                </v-card-title>
                <v-card-text class="pa-5 pt-0">
                  <v-row no-gutters>
                    <v-col>
                      <ApexChart
                        v-if="chartLoading"
                        type="line"
                        height="500"
                        :options="chartData.options"
                        :series="chartData.series">
                      </ApexChart>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-tab-item>
        </v-tabs>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState, mapActions } from 'vuex';
import ApexChart from 'vue-apexcharts';
import TimeRange from './TimeRange.vue';

export default {
  name: 'Dashboard',
  components: {
    ApexChart, TimeRange
  },
  data() {
    return {
      dialog: false,
      dialogm1: "",
      isFetching: false,
      tableMenu: [],
      tableData: [],
      tableDataIndex: 0,
      tableProperty: {
        search: '',
        headers: [
          {
            text: 'Time',
            align: 'start',
            sortable: true,
            value: 'time',
          },
          { text: 'DeviceName', value: 'deviceName' },
          { text: 'DataName', value: 'dataName' },
          { text: 'Value', value: 'value' },
        ],
      },
      
      device_list: [],
      device_items: [],
      device_value: '',

      field_items: [],
      field_value: [],

      chartLoading: true,
      chartData: {
        series: [

        ],
        options: {
          chart: {
            id: 'mainApexArea',
            width: '100%',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          markers: {
            size: [3,3,3,3,3,3,3,3,3,3],
          },
          stroke: {
            width: [1,1,1,1,1,1,1,1,1,1],
            curve: ['smooth', 'smooth', 'smooth', 'smooth', 'smooth', 'smooth', 'smooth', 'smooth', 'smooth', 'smooth'],
          },
          fill: {
            type: 'linear',
          },
          legend: {
            show: false,
          },
          colors: ['#FFC260', '#536dfe', '#F8F9FF', '#FFC260', '#536dfe', '#F8F9FF', '#FFC260', '#536dfe', '#F8F9FF', '#FFC260'],
          yaxis: {
            axisBorder: {
              show: true,
              color: '#B9B9B980',
            },
            labels: {
              style: {
                colors: [
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                ],
                fontSize: '12px',
              },
            },
            tickAmount: 5,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '12px',
                colors: [
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                  '#B9B9B980',
                ],
              },
            },
            categories: [],
            tickAmount: 10,
          },
          theme: {
            mode: 'light',
          },
        },
      },
    }
  },
  created() {
      this.getDeviceItems();
  },
  mounted() {
    this.setFirstTime();
  },
  computed: {
    ...mapState('auth', {
      currentUser: (state) => state.currentUser,
    }),
    ...mapState('service', {
      startDate: (state) => state.timeRange_start,
      endDate: (state) => state.timeRange_end
    }),
    countTableData() {
      return this.tableMenu.length > 1 ? true : false;
    },
    isLoading() {
      return this.isFetching;
    },
    timeRange() {
      return `${this.startDate} ~ ${this.endDate}` 
    }
  },
  watch: {
    
  },
  methods: {
    ...mapActions('service', ['setStartTime', 'setEndTime']),
    setFirstTime() {
      this.setStartTime("now-5m");
      this.setEndTime("now");
    },
    changeTableData(value) {
      this.tableDataIndex = value;
    },
    getDeviceItems() {
      this.items = [];
      axios
        .get(`/devices/alldeviceswithfield/${this.currentUser.user.companyid}`)
        .then((res) => {
          if (res.status === 200) {
            this.device_list = res.data;
            this.device_items = [];
            this.device_list.forEach(device => {
              this.device_items.push(device.name)
            }); 
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getFieldItems() {

    },
    onDeviceChange(value) {
      this.field_items = [];
      this.field_value = [];
      this.device_list.forEach(device => {
        if (device.name === value) {
          device.DeviceFields.forEach(fields => {
            this.field_items.push(fields.name);
          })
        }
      }); 
    },
    formatDate(timestamp) {
      var x = new Date(timestamp);

      var yy = x.getFullYear();
      var MM = x.getMonth() + 1;
      var dd = x.getDate();

      var hh = ('0' + x.getHours()).slice(-2);
      var mm = ('0' + x.getMinutes()).slice(-2);
      // var ss = ('0' + x.getSeconds()).slice(-2);
      // var sss =  ('0' + x.getMilliseconds()).slice(-3);

      return `${yy}-${MM}-${dd} ${hh}:${mm}`;
      // return `${yy}-${MM}-${dd} ${hh}:${mm}:${ss}.${sss}`;
    },
    formatDateForInflux(offset_m, offset_h, offset_d) {
      // var _date = new Date(2023, 0, 2, 10, 5, 0);
      var _date = new Date();
      
      var targetDate;
      if (offset_m > 0) targetDate = this.$moment(_date).subtract(offset_m, 'm').toDate();
      else if (offset_h > 0) targetDate = this.$moment(_date).subtract(offset_h, 'h').toDate();
      else if (offset_d > 0) targetDate = this.$moment(_date).subtract(offset_d, 'd').toDate()
      else targetDate = _date;

      targetDate = this.$moment(targetDate).subtract(9, 'h').toDate();//한국시간 9시간 빼준다.

      return this.$moment(targetDate).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    },
    setDate(value) {
      var r = "";
      switch (value) {
        case "now":
          r = this.formatDateForInflux(0, 0, 0);
          break;
        case "now-5m":
          r = this.formatDateForInflux(5, 0, 0);
          break;
        case "now-15m":
          r = this.formatDateForInflux(15, 0, 0);
          break;
        case "now-30m":
          r = this.formatDateForInflux(30, 0, 0);
          break;
        case "now-1h":
          r = this.formatDateForInflux(0, 1, 0);
          break;
        case "now-3h":
          r = this.formatDateForInflux(0, 3, 0);
          break;
        case "now-6h":
          r = this.formatDateForInflux(0, 6, 0);
          break;
        case "now-12h":
          r = this.formatDateForInflux(0, 12, 0);
          break;
        case "now-1d":
          r = this.formatDateForInflux(0, 0, 1);
          break;
        case "now-2d":
          r = this.formatDateForInflux(0, 0, 2);
          break;
        case "now-7d":
          r = this.formatDateForInflux(0, 0, 7);
        break;
      }
      return r;
    },
    sendQuery() {
      if (this.field_value.length > 0) {
        this.isFetching = true;
        this.chartLoading = false;
        var _startDate = "";
        var _endDate = "";
        if (this.startDate.substr(0, 3) === 'now') {
          _startDate = this.setDate(this.startDate);
        } else {
          _startDate = this.startDate + "T00:00:01.000Z";
        }
        if (this.endDate.substr(0, 3) === 'now') {
          _endDate = this.setDate(this.endDate);
        } else {
          _endDate = this.endDate + "T23:59:59.999Z";
        }
        axios
          .get(`/devices/querydata`, {
            params: {
              saupjaid: this.currentUser.user.companyid,
              deviceName: this.device_value,
              dataNames: this.field_value,
              startDate: _startDate,
              endDate: _endDate
            }
          })
          .then((res) => {
            if (res.status === 200) {
              this.chartData.series = [];
              this.chartData.options.xaxis.categories = [];
              this.tableMenu = [];
              this.tableData = [];
              var dataName = "";
              
              res.data.forEach(data => {
                dataName = data.dataName;

                var values = [];
                var categories = [];
                var _tableData = [];

                data.data.forEach(d => {
                  values.push(d.value);
                  categories.push(this.formatDate(d.time));

                  _tableData.push({
                    time: this.formatDate(d.time),
                    deviceName: this.device_value,
                    dataName: dataName,
                    value: d.value
                  });
                });
                
                this.chartData.series.push({
                  name: dataName,
                  data: values
                });
                this.tableData.push(_tableData);
                this.tableMenu.push(dataName);

                this.chartData.options.xaxis.categories = categories;
              });
              this.chartLoading = true;

              this.tableDataIndex = 0;

              this.isFetching = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.isFetching = false;
          });
      }
    },
  }
}
</script>