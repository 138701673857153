<template>
  <v-container fluid class="user-list mt-3">
    <v-row class="ma-0">
      <v-card width="100%" class="ma-1">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="1" class="d-flex justify-end mt-3">
              <v-icon size="28" :class="$vuetify.theme.dark ? 'primary--text' : null" @click="goBack()"
              >mdi-arrow-left</v-icon>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="ma-1">
          <v-card-text>
            <v-data-table
              :loading="isReceiving || isDeleting"
              loading-text="Loading... Please wait"
              class="users-table"
              :headers="headers"
              :items="items"
              sort-by="createdAt"
              :items-per-page="itemPerPage">
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserList',
  data() {
    return {
      id: '',
      items: [],
      headers: [
        { text: 'id', value: 'id' },
        { text: 'deviceType', value: 'deviceType' },
        { text: 'accountID', value: 'accountID' },
        { text: 'event', value: 'event' },
        { text: 'state', value: 'state' },
        { text: '생성일', value: 'createdAt' },
        { text: '수정일', value: 'updatedAt' },
        { text: '삭제일', value: 'deletedAt' },
      ],
      selected: [],
    }
  },

  computed: {

  },
  created () {
    this.id = this.$route.params.id
    this.getItems()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getItems() {
        this.items = [];
        axios
          .post(`/users/admin_get_sensor_events`, {
            id: this.id,
          })
          .then((res) => {
            if (res.status === 200) {
              this.items = res.data;
              console.log(this.items)
              if (this.items.length > 0) {
                //this.selectRow(0);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
  }
}
</script>

<!-- <style src="./List.scss" lang="scss"></style> -->
