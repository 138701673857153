<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent :retain-focus="false" width="auto">
      <v-card>
        <v-card-title>
          <span class="text-h5">Time Range</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4" lg="6">
                <v-radio-group column v-model="radios">
                  <v-radio label="Last 5 minutes" value="5m" @click="radioClicked()"></v-radio>
                  <v-radio label="Last 15 minutes" value="15m" @click="radioClicked()"></v-radio>
                  <v-radio label="Last 30 minutes" value="30m" @click="radioClicked()"></v-radio>
                  <v-radio label="Last 1 hour" value="1h" @click="radioClicked()"></v-radio>
                  <v-radio label="Last 3 hours" value="3h" @click="radioClicked()"></v-radio>
                  <v-radio label="Last 6 hours" value="6h" @click="radioClicked()"></v-radio>
                  <v-radio label="Last 12 hours" value="12h" @click="radioClicked()"></v-radio>
                  <v-radio label="Last 1 day" value="1d" @click="radioClicked()"></v-radio>
                  <v-radio label="Last 2 day" value="2d" @click="radioClicked()"></v-radio>
                  <v-radio label="Last 7 day" value="7d" @click="radioClicked()"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="6" class="ma-0 pa-0">
                <v-row no-gutter>
                  <v-col cols="12" class="pt-9" style="height: 80px">
                    <v-text-field readonly class="pa-0 ma-0" label="from" v-model="startDate"></v-text-field>
                  </v-col>
                  <v-col cols="12" style="height: 50px">
                    <v-btn class="mt-0 pt-0" color="primary" size="small" @click.stop="startdatetime_dialog = true">Start Date</v-btn>
                      <StartDateTime v-model="startdatetime_dialog"/>
                  </v-col>
                  <v-col cols="12" class="pt-9" style="height: 80px">
                    <v-text-field readonly class="pa-0 ma-0" label="to" v-model="endDate"></v-text-field>
                  </v-col>
                  <v-col cols="12" style="height: 50px">
                    <v-btn class="mt-0 pt-0" color="primary" size="small" @click.stop="enddatetime_dialog = true">End Date</v-btn>
                      <EndDateTime v-model="enddatetime_dialog"/>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="cancelModal()"
          >
            Close
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import StartDateTime from './StartDateTime.vue';
import EndDateTime from './EndDateTime.vue';
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      start: "start",
      end: "end",
      radios: "5m",
      startdatetime_dialog: false,
      enddatetime_dialog: false,
    }
  },
  components: {
    StartDateTime, EndDateTime
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('service', {
      startDate: (state) => state.timeRange_start,
      endDate: (state) => state.timeRange_end
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions('service', ['setStartTime', 'setEndTime', 'clearTimeRange']),
    cancelModal() {
      // this.clearTimeRange();
      this.setStartTime("now-5m");
      this.setEndTime("now");
      this.dialog = false;
    },
    radioClicked() {
      switch (this.radios) {
        case '5m':
          this.setStartTime("now-5m");
          this.setEndTime("now");
          break;
        case '15m':
          this.setStartTime("now-15m");
          this.setEndTime("now");
          break;
        case '30m':
          this.setStartTime("now-30m");
          this.setEndTime("now");
          break;
        case '1h':
          this.setStartTime("now-1h");
          this.setEndTime("now");
          break;
        case '3h':
          this.setStartTime("now-3h");
          this.setEndTime("now");
          break;
        case '6h':
          this.setStartTime("now-6h");
          this.setEndTime("now");
          break;
        case '12h':
          this.setStartTime("now-12h");
          this.setEndTime("now");
          break;
        case '1d':
          this.setStartTime("now-1d");
          this.setEndTime("now");
          break;
        case '2d':
          this.setStartTime("now-2d");
          this.setEndTime("now");
          break;
        case '7d':
          this.setStartTime("now-7d");
          this.setEndTime("now");
          break;
      }
    }
  }
};
</script>