<template>
  <v-container fluid class="px-0 px-md-1">
    <div class="tables-basic mt-3">
      <v-row>
        <v-col cols="6">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-5 pb-3">
              <p>Device List</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="items"
              item-key="name"
              @click:row="handleClick"
              :single-select="true"
            >
            </v-data-table>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card class="employee-list mb-1">
            <v-card-title class="pa-5 pb-3">
              <p>{{ df_tableTitle }}</p>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              v-model="df_selected"
              :headers="df_headers"
              :items="df_items"
              item-key="name"
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { mapState } from 'vuex';

  export default {
    name: 'Devices',
    data() {
      return {
        items: [],
        df_items: [],
        selected: [],
        df_selected: [],
        headers: [
          {
            text: 'id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
          { text: 'MacAddress', value: 'address' },
          { text: 'Type', value: 'DeviceType.devicetype' },
        ],
        df_headers: [
          {
            text: 'id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'name' },
        ],
      };
    },
    created() {
      this.getItems();
    },
    computed: {
      ...mapState('auth', {
        currentUser: (state) => state.currentUser,
      }),
      df_tableTitle() {
        if (this.selected.length === 0) {
          return 'Data Field List';
        } else {
          return `Data Field List (${this.selected[0].name})`;
        }
      },
    },
    methods: {
      getItems() {
        this.items = [];
        axios
          .get(`/devices/alldevices/${this.currentUser.user.companyid}`)
          .then((res) => {
            if (res.status === 200) {
              this.items = res.data;
              if (this.items.length > 0) {
                this.selectRow(0);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      selectRow(index) {
        this.selected.push(this.items[index]);
        this.df_items = this.items[index].DeviceFields;
      },
      handleClick(item, row) {
        row.select(true);
        this.df_items = item.DeviceFields;
      },
      
    },
  };
</script>

<style src="./tableBasic.scss" lang="scss"></style>
