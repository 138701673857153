export default [
  {
    id: '1',
    select: false,
    img: require('@/assets/img/e-commerce/low/1.png'),
    title: 'Trainers',
    subtitle: 'Trainers in white',
    price: '$80',
    rating: '4.6',
    api: true,
  },
  {
    id: '2',
    select: false,
    img: require('@/assets/img/e-commerce/low/2.png'),
    title: 'Boots',
    subtitle: 'Trainers in blue',
    price: '$37',
    rating: '4.6',
    api: true,
  },
  {
    id: '3',
    select: false,
    img: require('@/assets/img/e-commerce/low/3.png'),
    title: 'Flat sandals',
    subtitle: 'Trainers in white',
    price: '$70',
    rating: '4.6',
    api: true,
  },
  {
    id: '4',
    select: false,
    img: require('@/assets/img/e-commerce/low/4.png'),
    title: 'Trainers',
    subtitle: 'Trainers in blue',
    price: '$85',
    rating: '4.6',
    api: true,
  },
  {
    id: '5',
    select: false,
    img: require('@/assets/img/e-commerce/low/5.png'),
    title: 'Flat sandals',
    subtitle: 'Trainers in white',
    price: '$12',
    rating: '4.6',
    api: true,
  },
  {
    id: '6',
    select: false,
    img: require('@/assets/img/e-commerce/low/6.png'),
    title: 'Flat sandals',
    subtitle: 'Trainers in blue',
    price: '$76',
    rating: '4.6',
    api: true,
  },
  {
    id: '7',
    select: false,
    img: require('@/assets/img/e-commerce/low/1.png'),
    title: 'Trainers',
    subtitle: 'Trainers in white',
    price: '$80',
    rating: '4.6',
    api: '1234',
  },
  {
    id: '8',
    select: false,
    img: require('@/assets/img/e-commerce/low/2.png'),
    title: 'Boots',
    subtitle: 'Trainers in blue',
    price: '$37',
    rating: '4.6',
    api: true,
  },
  {
    id: '9',
    select: false,
    img: require('@/assets/img/e-commerce/low/3.png'),
    title: 'Flat sandals',
    subtitle: 'Trainers in white',
    price: '$70',
    rating: '4.6',
    api: true,
  },
  {
    id: '10',
    select: false,
    img: require('@/assets/img/e-commerce/low/4.png'),
    title: 'Trainers',
    subtitle: 'Trainers in blue',
    price: '$85',
    rating: '4.6',
    api: true,
  },
  {
    id: '11',
    select: false,
    img: require('@/assets/img/e-commerce/low/5.png'),
    title: 'Flat sandals',
    subtitle: 'Trainers in white',
    price: '$12',
    rating: '4.6',
    api: true,
  },
  {
    id: '12',
    select: false,
    img: require('@/assets/img/e-commerce/low/6.png'),
    title: 'Flat sandals',
    subtitle: 'Trainers in blue',
    price: '$76',
    rating: '4.6',
    api: true,
  },
]
