<template>
  <v-card class="mt-6 py-3">
    <form @submit.prevent="submitHandler">
      <div class="px-8">
        <v-row>
          <v-col cols="12">
            <h4 class="page-title">User Information</h4>
          </v-col>

          <v-col cols="3" class="d-flex align-center">
            <p class="fs-normal greyBold--text mb-0">E-Mail</p>
          </v-col>
          <v-col cols="9">
            <v-text-field label="email" v-model="email" readonly disabled></v-text-field>
          </v-col>

          <v-col cols="3" class="d-flex align-center">
            <p class="fs-normal greyBold--text mb-0">name</p>
          </v-col>
          <v-col cols="9">
            <v-text-field label="Name" v-model="name"></v-text-field>
          </v-col>

          <v-col cols="3" class="d-flex align-center">
            <p class="fs-normal greyBold--text mb-0">Zip</p>
          </v-col>
          <v-col cols="9">
            <v-text-field label="Zip" v-model="addr_zip"></v-text-field>
          </v-col>

          <v-col cols="3" class="d-flex align-center">
            <p class="fs-normal greyBold--text mb-0">Addr</p>
          </v-col>
          <v-col cols="9">
            <v-text-field label="Addr" v-model="addr"></v-text-field>
          </v-col>

          <v-col cols="3" class="d-flex align-center">
            <p class="fs-normal greyBold--text mb-0">Handphone</p>
          </v-col>
          <v-col cols="9">
            <v-text-field label="Handphone" v-model="mobilephone"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="d-flex align-center">
            <p class="fs-normal greyBold--text mb-0">tel</p>
          </v-col>
          <v-col cols="9">
            <v-text-field label="tel" v-model="tel"
            ></v-text-field>
          </v-col>

          <v-col cols="3" class="d-flex align-center">
            <p class="fs-normal greyBold--text mb-0">snsId</p>
          </v-col>
          <v-col cols="9">
            <v-text-field label="snsId" v-model="snsId"></v-text-field>
          </v-col>

          <v-col cols="3" class="d-flex align-center">
            <p class="fs-normal greyBold--text mb-0">provider</p>
          </v-col>
          <v-col cols="9">
            <v-text-field label="provider" v-model="provider"></v-text-field>
          </v-col>

          <v-col cols="3" class="d-flex align-center">
            <p class="fs-normal greyBold--text mb-0">Admin</p>
          </v-col>
          <v-col cols="9">
            <v-text-field label="admin" v-model="admin"></v-text-field>
          </v-col>

          <v-col cols="12 mt-5">
            <v-btn type="submit" color="primary"> Save </v-btn>

            <v-btn @click="formatData" class="ml-2"> Reset </v-btn>

            <router-link :to="cancelUrl" class="text-decoration-none">
              <v-btn type="button" class="ml-2"> Cancel </v-btn>
            </router-link>
            
          </v-col>
        </v-row>
      </div>
    </form>
  </v-card>
</template>
<script>
  import { mapState, mapActions, mapMutations } from 'vuex';

  export default {
    data() {
      return {
        id: '',
        email: '',
        password: '',
        name: '',
        addr_zip: '',
        addr: '',
        mobilephone: '',
        tel: '',
        snsId:'',
        provider: '',
        admin: false
      };
    },
    computed: {
      ...mapState({
        data: (state) => state.usersForm.data,
      }),

      cancelUrl() {
        return (
          '/' + this.$route.fullPath.split('/').slice(1).splice(0, 2).join('/')
        );
      },
    },
    methods: {
      ...mapMutations({
        showSnackbar: 'snackbar/showSnackbar',
      }),
      ...mapActions({
        getData: 'usersForm/getData',
        edit: 'usersForm/edit',
      }),
      
      async submitHandler() {
        const data = this.data;

        data.id = this.id;
        data.email = this.email;
        data.password = this.password;
        data.name = this.name;
        data.addr_zip = this.addr_zip;
        data.addr = this.addr;
        data.mobilephone = this.mobilephone;
        data.tel = this.tel;
        data.snsId = this.snsId;
        data.provider = this.provider;
        data.admin = this.admin;

        try {
          await this.edit({ id: this.id, data });
          //this.$router.push('/admin/users');
        } catch (e) {
          this.showSnackbar(e);
        }
      },

      formatData() {
        this.id = this.data.id;
        this.email = this.data.email;
        this.password = this.data.password;
        this.name = this.data.name;
        this.addr_zip = this.data.addr_zip;
        this.addr = this.data.addr;
        this.mobilephone = this.data.mobilephone;
        this.tel = this.data.tel;
        this.snsId = this.data.snsId;
        this.provider = this.data.provider;
        this.admin = this.data.admin;
      },

    },
    async beforeMount() {
      try {
        console.log('beforeMount()');
        const user = JSON.parse(localStorage.getItem('user'));
        const id = user.id
        if (id) {
          this.id = id;
          await this.getData(id);
          this.formatData();
        }
      } catch (e) {
        this.showSnackbar(e);
      }
    },
    watch: {},
    // eslint-disable-next-line vue/no-unused-components
  };
</script>
