<template>
  <v-card class="mt-6 py-3">
    <div class="px-8">
      <v-row>
        <v-col cols="1">
          <v-icon size="28" :class="$vuetify.theme.dark ? 'primary--text' : null" @click="goBack()"
          >mdi-arrow-left</v-icon>
        </v-col>

        <v-col cols="11">
          <h4 class="page-title">User Information</h4>
        </v-col>

        <v-col cols="9">
          <v-text-field label="id" v-model="user.id" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="email" v-model="user.email" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="name" v-model="user.name" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="parentName" v-model="user.parentName" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="parentAge" v-model="user.parentAge" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="parentPhone" v-model="user.parentPhone" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="parentSex" v-model="user.parentSex" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="addr_zip" v-model="user.addr_zip" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="addr" v-model="user.addr" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="addr_detail" v-model="user.addr_detail" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="mobilephone" v-model="user.mobilephone" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="tel" v-model="user.tel" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="snsId" v-model="user.snsId" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="provider" v-model="user.provider" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="admin" v-model="user.admin" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="shareKey" v-model="user.shareKey" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="deviceID" v-model="user.deviceID" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="optionalCheck" v-model="user.optionalCheck" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="sendPushToiletLastUsed" v-model="user.sendPushToiletLastUsed" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="sendPushRefrigeratorLastUsed" v-model="user.sendPushRefrigeratorLastUsed" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="sendPushEnteranceEvent" v-model="user.sendPushEnteranceEvent" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="enableAlarm" v-model="user.enableAlarm" readonly></v-text-field>
        </v-col>

        <v-col cols="9">
          <v-text-field label="useAirplaneMode" v-model="user.useAirplaneMode" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="isRefrigeratorOpened" v-model="user.isRefrigeratorOpened" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="sendPushRefrigeratorOpened" v-model="user.sendPushRefrigeratorOpened" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="sendPushEnteranceOpened" v-model="user.sendPushEnteranceOpened" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="refrigeratorAlarmTime" v-model="user.refrigeratorAlarmTime" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="toiletAlarmTime" v-model="user.toiletAlarmTime" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="entranceOpenedAlarmTime" v-model="user.entranceOpenedAlarmTime" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="refrigeratorOpenedAlarmTime" v-model="user.refrigeratorOpenedAlarmTime" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="entranceOpenedAlarmTime" v-model="user.entranceOpenedAlarmTime" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="createdAt" v-model="user.createdAt" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="updatedAt" v-model="user.updatedAt" readonly></v-text-field>
        </v-col>
        <v-col cols="9">
          <v-text-field label="deletedAt" v-model="user.deletedAt" readonly></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>

  export default {
    data() {
      return {
        user: {
          id: '',
          email: '',
          name: '',
          parentName: '',
          parentAge: '',
          parentPhone: '',
          parentSex: '',
          addr_zip: '',
          addr: '',
          addr_detail: '',
          mobilephone: '',
          tel: '',
          snsId: '',
          provider: '',
          admin: '',
          shareKey: '',
          deviceID: '',
          optionalCheck: '',
          sendPushToiletLastUsed: '',
          sendPushRefrigeratorLastUsed: '',
          sendPushEnteranceEvent: '',
          enableAlarm: '',
          useAirplaneMode: '',
          isRefrigeratorOpened: '',
          sendPushRefrigeratorOpened: '',
          sendPushEnteranceOpened: '',
          refrigeratorAlarmTime: '',
          toiletAlarmTime: '',
          entranceOpenedAlarmTime: '',
          refrigeratorOpenedAlarmTime: '',
          createdAt: '',
          updatedAt: '',
          deletedAt: '',
        },
        
      };
    },
    computed: {

    },
    created() {
      this.user = this.$route.params.user
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      }
    }

  };
</script>
