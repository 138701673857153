// import config from '../config';
import axios from 'axios';
// import decode from 'jwt-decode';
import router from '../router';

export default {
  namespaced: true,
  state: {
    isFetching: false,
    errorMessage: '',
    currentUser: null,
  },
  mutations: {
    LOGIN_FAILURE(state, payload) {
      state.isFetching = false;
      state.errorMessage = payload;
      state.currentUser = null;
    },
    LOGIN_SUCCESS(state, user) {
      state.isFetching = false;
      state.errorMessage = '';
      state.currentUser = user || null;
    },
    LOGIN_REQUEST(state) {
      state.isFetching = true;
    },
  },
  actions: {
    receiveToken({ commit }, token) {
      console.log("receiveToken()", token)
      //let user = decode(token); //{id: 'dn9318dn@gmail.com', iat: 1692608611, exp: 1692610411, iss: 'IotDataPlatform'}
      //localStorage.setItem('token', token);
      //localStorage.setItem('user', JSON.stringify(user));
      //axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      commit('LOGIN_SUCCESS');
      //router.push('/');
    },

    async findMe({ dispatch }) {
      try {
        console.log("findMe()");
        const response = await axios.get('/auth/adminFindMe');
        console.log(response.data);
        return response.data;
      } catch (e) {
        dispatch('snackbar/showSnackbar', e, { root: true });
        dispatch('logoutUser');
      }
    },

    async doInit({ dispatch, commit }) {
      try {
        console.log("doInit()")
        let currentUser = null;
        // let token = localStorage.getItem('token');
        
        // if (token) {
          currentUser = await dispatch('findMe');
          commit('LOGIN_SUCCESS', currentUser);
          router.push('/Profile');
        // }
        
      } catch (e) {
        commit('LOGIN_FAILURE', e);
      }
    },

    async loginUser({ dispatch, commit }, payload) {
      dispatch('requestLogin');
       if (payload.email && payload.password) {
        try {
          await axios.post('/auth/adminSignIn/', payload).then((res) => {
            if (res.status === 200) {
              console.log("login ok")

              let currentUser = res.data.user;
              let token = res.data.token;

              console.log(currentUser)
              console.log(token)

              localStorage.setItem('token', token);
              localStorage.setItem('user', JSON.stringify(currentUser));
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

              commit('LOGIN_SUCCESS', currentUser);
              router.push('/'); //Profile 페이지로 Go ~~~~~~~~~~~~~~~~~~~~~~~~~

            } else if (res.status === 201) {
              console.log("login 실패")
              console.log(res.data)
            }
          });
        } catch (e) {
          dispatch('snackbar/showSnackbar', e, { root: true });
          dispatch('loginError', e.response.data.message);
        }
      } else {
        dispatch('loginError', 'Something was wrong. Try again');
      }
    },
    
    async logoutUser() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      axios.defaults.headers.common['Authorization'] = '';

      await axios.get('/auth/logout');
      router.push('/login');
    },
    loginError({ commit }, payload) {
      commit('LOGIN_FAILURE', payload);
    },
    requestLogin({ commit }) {
      commit('LOGIN_REQUEST');
    },
    receiveLogin({ commit }) {
      commit('LOGIN_SUCCESS');
    },
  },
};
